<script>
import ModalVideo from '@/components/ModalVideo'
import LayoutMixin from '@/components/LayoutMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [LayoutMixin],

  data() {
    return {
      urlAjuda: 'https://www.youtube.com/embed/_bACnFx7JmQ',
    }
  },

  components: {
    ModalVideo,
  },

  computed: {
    ...mapGetters('user', ['isUSA']),

    mobile() {
      const breakpoint = this.$vuetify.breakpoint.name
      return ['sm', 'xs'].includes(breakpoint)
    },

    tutorial_toggle() {
      return process.env.VUE_APP_TUTORIAL_ENABLED === 'true' && !this.isUSA
    },
  },

  methods: {
    async createFarm() {
      await this.$emit('clear-current-farm')
      this.$emit('open-modal-farms')
    },
  },
}
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-row v-if="mobile" class="flex-container ml-0">
        <v-col cols="6" xs="3" sm="3" style="font-size: 22px">{{
          $t('fazenda.fazendas')
        }}</v-col>

        <v-col
          v-if="tutorial_toggle"
          xs="6"
          sm="3"
          style="font-size: 12px"
          class="d-flex justify-end align-center"
        >
          <modal-video :url-video="urlAjuda" class="mr-5 d-inline" />
        </v-col>

        <v-col xs="6" sm="3" style="font-size: 12px">
          <a style="color: #8bc34a" href="/api-fazendas/importar/">
            <b>
              {{ $t('safra.register_previous_season') }}
            </b>
          </a>
        </v-col>

        <v-col xs="4" sm="3" class="d-flex justify-end">
          <v-btn
            dark
            color="#8bc34a"
            height="30px"
            width="130px"
            style="font-size: 10px"
            class="rounded-lg mr-5"
            @click="createFarm"
          >
            {{ $t('fazenda.create_farm') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else class="d-inline-flex mt-4" :class="'ml-3'">
        <div class="display-1">{{ $t('fazenda.fazendas') }}</div>
        <div v-if="tutorial_toggle" class="ml-5">
          <ModalVideo
            :url-video="urlAjuda"
            event="tutorial_programa"
            name="header_fazenda"
          />
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>
<style scoped>
.flex-container {
  display: flex;
  align-items: center;
}
.left-position {
  margin-left: auto;
}
</style>
